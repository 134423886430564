import { ElementRef, Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Intercom } from '@supy-io/ngx-intercom';
import { BehaviorSubject, concatMap, debounceTime, distinctUntilChanged, Observable, ReplaySubject, tap } from 'rxjs';
import { HelpModalComponent, MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { DestinationZone, EcommerceDestinationCountry, SalesChannel } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';

@Injectable()
export class SelectDestinationCountryModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly filteredCountriesSubject: ReplaySubject<EcommerceDestinationCountry[]> = new ReplaySubject<
    EcommerceDestinationCountry[]
  >(1);
  filteredCountries$: Observable<EcommerceDestinationCountry[]> = this.filteredCountriesSubject.asObservable();

  private readonly activeMenuTypeSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  activeMenuType$: Observable<string> = this.activeMenuTypeSubject.asObservable();

  private readonly menuItemsSubject: ReplaySubject<{ id: string; label: string }[]> = new ReplaySubject<
    { id: string; label: string }[]
  >(1);
  menuItems$: Observable<{ id: string; label: string }[]> = this.menuItemsSubject.asObservable();

  private readonly refreshDestinationZonesSubject: ReplaySubject<void> = new ReplaySubject<void>(1);
  refreshDestinationZones$: Observable<DestinationZone[]> = this.refreshDestinationZonesSubject.asObservable().pipe(
    tap(() => this.isLoading.next(true)),
    concatMap(() =>
      this.ecommerceSaleStateService.getDestinationCountries(
        this.data.operation,
        this.data.sectionId,
        this.data.subSectionId,
      ),
    ),
    tap((destinationZones: DestinationZone[]) => this.assignNecessaryValuesFromDestinationZones(destinationZones)),
    tap(() => this.addClickEventListenerOnInjectedHTML()),
    tap(() => this.isLoading.next(false)),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      sectionId: string;
      subSectionId: string;
      operation: Operation;
      salesChannel?: SalesChannel;
      canHavePackageType: boolean;
    },
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly intercom: Intercom,
    private readonly modalRef: ModalRef,
    private readonly elementRef: ElementRef,
    private readonly modalService: ModalService,
  ) {}

  hasSearch = false;
  public destinationZones!: DestinationZone[];
  public selectedZone?: DestinationZone;
  public menuItems!: { id: string; label: string }[];
  public activeMenuType?: string;
  public allCountries!: EcommerceDestinationCountry[];
  public filteredCountries!: EcommerceDestinationCountry[];
  public searchFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  private filterCountries(): void {
    this.selectedZone = this.destinationZones.find((zone) => zone.type === this.activeMenuType);
    const searchValue: string = this.searchFormControl.value.toLowerCase();
    if (searchValue) {
      this.filteredCountries = this.allCountries.filter(
        (country) =>
          country.label.toLowerCase().includes(searchValue) || country.subLabel.toLowerCase().includes(searchValue),
      );
    } else if (this.selectedZone) {
      this.filteredCountries = this.selectedZone.countries.filter(
        (country) =>
          country.label.toLowerCase().includes(searchValue) || country.subLabel.toLowerCase().includes(searchValue),
      );
    }
    this.filteredCountriesSubject.next(this.filteredCountries);
  }

  readonly searchCountry$: Observable<string> = this.searchFormControl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap((search: string) => (this.hasSearch = !!search)),
    tap((search: string) => {
      this.filterCountries();
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchModalResearched(
        'country tax rate modal',
        this.data.subSectionId,
        search,
        this.filteredCountries.length,
        this.data.operation,
        this.data.salesChannel,
      );
    }),
  );

  refreshDestinationZones(): void {
    this.refreshDestinationZonesSubject.next();
  }

  selectCountry(country: EcommerceDestinationCountry): void {
    this.modalRef.close({
      sectionId: this.data.sectionId,
      subSectionId: this.data.subSectionId,
      saleId: country.saleId,
      selectedZone: this.selectedZone?.type,
    });
  }

  private assignNecessaryValuesFromDestinationZones(destinationZones: DestinationZone[]): void {
    this.assignDestinationZonesAndAllCountries(destinationZones);
    this.assignActiveMenu(destinationZones);
    this.filterCountries();
  }

  private assignDestinationZonesAndAllCountries(destinationZones: DestinationZone[]): void {
    this.allCountries = destinationZones.reduce(
      (allCountries, zone) => allCountries.concat(...zone.countries),
      [] as EcommerceDestinationCountry[],
    );
    this.destinationZones = destinationZones;
  }

  private assignActiveMenu(destinationZones: DestinationZone[]): void {
    this.menuItems = destinationZones.map((destinationZone) => ({
      id: destinationZone.type,
      label: destinationZone.title,
    }));
    this.activeMenuType = this.menuItems?.[0]?.id;
    this.menuItemsSubject.next(this.menuItems);
    this.activeMenuTypeSubject.next(this.activeMenuType);
  }

  onMenuChange(e: MouseEvent, menuItem: { id: string; label: string }): void {
    e.preventDefault();
    if (this.activeMenuType === menuItem.id) {
      return;
    }
    this.activeMenuType = menuItem.id;
    this.activeMenuTypeSubject.next(this.activeMenuType);
    this.filterCountries();
  }

  onClearSearch(): void {
    this.searchFormControl.reset('');
  }

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show();
  }

  private addClickEventListenerOnInjectedHTML(): void {
    const europeanStateModalButton: HTMLElement =
      this.elementRef.nativeElement.querySelector('.european-state-modal-button');
    if (europeanStateModalButton) {
      europeanStateModalButton.addEventListener('click', (e) => this.openEuropeanUnionStatesModal(e));
    }
  }

  private openEuropeanUnionStatesModal(e: Event): void {
    e.stopPropagation();
    this.modalService.open(HelpModalComponent, {
      data: {
        title: "Pays membres de l'Union européenne",
        body:
          "Les pays membres de l'Union européenne sont l'Allemagne, l'Autriche, la Belgique, la Bulgarie, Chypre, " +
          "la Croatie, le Danemark, l'Espagne, l'Estonie, la Finlande, " +
          "la France, la Grèce, la Hongrie, l'Irlande, l'Italie, la Lettonie, " +
          'la Lituanie, le Luxembourg, Malte, les Pays-Bas, la Pologne, ' +
          'le Portugal, la République tchèque, la Roumanie, ' +
          'la Slovaquie, la Slovénie et la Suède.',
      },
    });
  }
}
