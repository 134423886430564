<div dougsModalTitle>
  <h6>Sélectionnez le taux de TVA du pays de livraison</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div class="force-max-height no-padding" dougsModalContent>
  <ng-container *ngIf="selectDestinationCountryModalComponentService.refreshDestinationZones$ | async"></ng-container>
  <ng-container *ngIf="selectDestinationCountryModalComponentService.searchCountry$ | async"></ng-container>
  <div class="ecommerce-modal-content__container">
    <div>
      <div class="menu__container">
        <dougs-search-bar
          #searchBar
          class="mb-24"
          [formControl]="selectDestinationCountryModalComponentService.searchFormControl"
          [searchInProgress]="!!selectDestinationCountryModalComponentService.searchFormControl.value"
          (clearSearch)="selectDestinationCountryModalComponentService.onClearSearch()"
          size="small"
        ></dougs-search-bar>
        <div
          class="my-16 px-32"
          *ngIf="
            !(companyStateService.activeCompany$ | async).accountingConfiguration.hasIoss &&
            selectDestinationCountryModalComponentService.data.subSectionId === 'outsideEu'
          "
        >
          <dougs-panel-info type="warning">
            <p class="small color-primary-700">
              <i class="fal fa-info-circle color-warning mr-4"></i>
              Attention, d'après vos paramètres, vous n'êtes pas inscrit à l'iOSS, vous devrez donc faire une
              déclaration nationale dans le pays de destination. Vous pouvez prendre un rendez-vous avec un comptable
              pour plus d'informations.
            </p>
          </dougs-panel-info>
        </div>
        <div
          class="my-16 px-32"
          *ngIf="
            !(companyStateService.activeCompany$ | async).accountingConfiguration.hasOss &&
            selectDestinationCountryModalComponentService.data.subSectionId != 'fr' &&
            selectDestinationCountryModalComponentService.data.subSectionId != 'outsideEu'
          "
        >
          <dougs-panel-info type="warning">
            <p class="small color-primary-700">
              <i class="fal fa-info-circle color-warning mr-4"></i>
              Attention, d'après vos paramètres, vous n'êtes pas inscrit à l'OSS, vous devrez donc faire une déclaration
              nationale. Vous pouvez prendre un rendez-vous avec un comptable pour plus d'informations.
            </p>
          </dougs-panel-info>
        </div>
        <div
          *ngIf="
            !selectDestinationCountryModalComponentService.hasSearch &&
            selectDestinationCountryModalComponentService.menuItems?.length
          "
          class="px-32"
        >
          <ul>
            <li
              *ngFor="
                let menuItem of selectDestinationCountryModalComponentService.menuItems$ | async;
                trackBy: 'id' | trackBy
              "
            >
              <a
                (click)="selectDestinationCountryModalComponentService.onMenuChange($event, menuItem)"
                href="#"
                [ngClass]="{
                  active: (selectDestinationCountryModalComponentService.activeMenuType$ | async) === menuItem.id
                }"
                class="p-4"
              >
                {{ menuItem.label }}
              </a>
            </li>
          </ul>
          <div class="menu__border-bottom"></div>
        </div>
      </div>
      <ng-container *ngIf="!(selectDestinationCountryModalComponentService.isLoading$ | async); else loader">
        <div
          class="mt-16 px-32"
          *ngIf="(selectDestinationCountryModalComponentService.filteredCountries$ | async)?.length"
        >
          <p
            class="tiny color-gray-350"
            *ngIf="
              selectDestinationCountryModalComponentService.selectedZone.subTitle &&
              !selectDestinationCountryModalComponentService.hasSearch
            "
          >
            {{ selectDestinationCountryModalComponentService.selectedZone.subTitle }}
          </p>
          <div
            (click)="selectDestinationCountryModalComponentService.selectCountry(country)"
            class="country-line pointer py-8"
            *ngFor="
              let country of selectDestinationCountryModalComponentService.filteredCountries$ | async;
              trackBy: 'flag' | trackBy
            "
          >
            <div class="country-line__flag">
              <img *ngIf="country.flag" class="mr-8" src="images/flags/{{ country.flag }}.svg" />
            </div>
            <div
              [ngClass]="{
                'country-line__label':
                  selectDestinationCountryModalComponentService.selectedZone.type !== 'exonerateSale'
              }"
            >
              <p class="small color-primary-700">
                {{ country.label }}
                <span
                  *ngIf="
                    country.subLabel &&
                    selectDestinationCountryModalComponentService.selectedZone.type !== 'exonerateSale'
                  "
                >
                  -&nbsp;
                </span>
              </p>
              <p class="tiny" [innerHtml]="country.subLabel"></p>
            </div>
          </div>
        </div>
        <dougs-blank-state
          class="px-32 mt-32"
          *ngIf="!selectDestinationCountryModalComponentService.filteredCountries?.length"
        >
          <h5>Aucun résultat</h5>
        </dougs-blank-state>
      </ng-container>
    </div>
    <div
      class="mt-32 px-32"
      *ngIf="
        selectDestinationCountryModalComponentService.selectedZone?.panelInfo?.label &&
        !selectDestinationCountryModalComponentService.hasSearch
      "
    >
      <dougs-panel-info type="default-light">
        <p class="small color-primary-700">
          {{ selectDestinationCountryModalComponentService.selectedZone.panelInfo.label }}
          <a
            href="#"
            (click)="selectDestinationCountryModalComponentService.openIntercom($event)"
            *ngIf="selectDestinationCountryModalComponentService.selectedZone.panelInfo.link.send === 'intercom'"
          >
            {{ selectDestinationCountryModalComponentService.selectedZone.panelInfo.link.label }}
          </a>
        </p>
      </dougs-panel-info>
    </div>
  </div>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
